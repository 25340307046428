import React from "react";
import Layout from "../Layout/Layout";
import styled from "styled-components";
const OfertaPage = () => {
  return (
    <Layout>
      <PoliticsBlock>
        <PoliticsInfo>
          <H2>1. ЗАГАЛЬНІ ПОЛОЖЕННЯ </H2>
          <H4>
            1.1. Використання Користувачем сайту означає згоду з цією Політикою
            конфіденційності та умовами обробки персональних даних Користувача.
          </H4>
          <H4>
            1.2. У разі незгоди з умовами Політики конфіденційності Користувач
            повинен припинити використання сайту.
          </H4>
          <H4>
            1.3. Справжня Політика конфіденційності застосовується лише до цього
            сайту. Адміністрація сайту не контролює і не несе відповідальність
            за сайти третіх осіб, на які Користувач може перейти по посиланню,
            яке доступне на сайті.
          </H4>
          <H4>
            1.4. Адміністрація сайту не перевіряє достовірність персональних
            даних, які надаються Користувачем сайту.
          </H4>
          <H2>2. ПРЕДМЕТ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ</H2>
          <H4>
            2.1. Справжня Політика конфіденційності встановлює зобов'язання
            Адміністрації сайту щодо нерозголошення та забезпечення режиму
            захисту конфіденційності персональних даних, які Користувач повинен
            за вимогою надати Адміністрації сайту.
          </H4>
          <H4>
            2.2. Персональні дані, дозволені до обробки в рамках цієї Політики
            конфіденційності, надаються Користувачем шляхом заповнення
            реєстраційної форми на сайті.
          </H4>
          <H2> 3. ЦІЛІ ЗБОРУ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ КОРИСТУВАЧА</H2>
          <H4>
            3.1. Персональні дані Користувача Адміністрація сайту може
            використовувати в цілях:
          </H4>
          <H6>3.1.1. Ідентифікації Користувача для оформлення замовлення.</H6>
          <H6>
            3.1.2. Надання Користувачеві доступу до персоналізованих ресурсів
            Сайту.
          </H6>
          <H6>
            3.1.3. Встановлення з Користувачем зворотного зв'язку, включаючи
            напрямок повідомлень, запитів, що стосуються використання Сайту,
            надання послуг, обробка запитів і заявок від Користувача.
          </H6>
          <H6>
            3.1.4. Визначення місця знаходження Користувача для забезпечення
            безпеки, запобігання шахрайства.
          </H6>
          <H6>
            3.1.5. Підтвердження достовірності та повноти персональних даних,
            наданих Користувачем.
          </H6>
          <H6> 3.1.6. Повідомлення Користувача Сайту про стан Замовлення.</H6>
          <H6>
            3.1.7. Надання Користувачеві ефективної клієнтської і технічної
            підтримки при виникненні проблем, пов'язаних з використанням Сайту.
          </H6>
          <H6>
            3.1.8. Надання Користувачеві з його згоди, оновлень продукції,
            спеціальних пропозицій, інформації про ціни, розсилки новин та інших
            відомостей від імені сайту або від імені партнерів сайту.
          </H6>
          <H6>3.1.9. Здійснення рекламної діяльності за згодою Користувача.</H6>
          <H6>
            3.1.10. Надання доступу Користувачеві на сайти або сервіси партнерів
            з метою отримання продуктів, оновлень і послуг.
          </H6>
          <H2>4. ЗОБОВ'ЯЗАННЯ СТОРІН</H2>
          <H4>4.1. Користувач зобов'язаний:</H4>
          <H6>
            4.1.1. Надати інформацію про персональні дані, необхідну для
            користування Сайтом.
          </H6>
          <H6>
            4.1.2. Оновити, доповнити надану інформацію про персональні дані в
            разі зміни даної інформації.
          </H6>
          <H4>4.2. Адміністрація сайту зобов'язана:</H4>
          <H6>
            4.2.1. Використовувати отриману інформацію виключно для цілей,
            зазначених у п. 3 цієї Політики конфіденційності.
          </H6>
          <H6>
            4.2.2. Забезпечити зберігання конфіденційної інформації в таємниці,
            не розголошувати без попередньої письмової згоди Користувача, а
            також не здійснювати продаж, обмін, опублікування, або розголошення
            іншими можливими способами переданих персональних даних Користувача.
          </H6>
          <H6>
            4.2.3. Вживати заходів обережності для захисту конфіденційності
            персональних даних Користувача згідно з порядком, який зазвичай
            використовується для захисту такого роду інформації в існуючому
            діловому обороті.
          </H6>
          <H6>
            4.2.4. Здійснити блокування персональних даних, що відносяться до
            відповідного Користувачеві, з моменту звернення або запиту
            Користувача або його законного представника або уповноваженого
            органу з захисту прав суб'єктів персональних даних на період
            перевірки, в разі виявлення недостовірних персональних даних або
            неправомірних дій.
          </H6>
          <H2>5. ВІДПОВІДАЛЬНІСТЬ СТОРІН</H2>
          <H4>
            5.1. Адміністрація сайту, яка не виконала свої зобов'язання, несе
            відповідальність за збитки, понесені Користувачем у зв'язку з
            неправомірним використанням персональних даних, відповідно до
            законодавства України.
          </H4>
          <H4>
            5.2. У разі втрати або розголошення Конфіденційної інформації
            Адміністрація сайту не несе відповідальність, якщо дана
            конфіденційна інформація:
          </H4>
          <H6>
            5.2.1. Стала публічним надбанням до її втрати або розголошення.
          </H6>
          <H6>
            5.2.2. Була отримана від третьої сторони до моменту її отримання
            Адміністрацією сайту.
          </H6>
          <H6>5.2.3. Була розголошена за згодою Користувача.</H6>
          <H2>6. ВИРІШЕННЯ СПОРІВ</H2>
          <H4>
            6.1. До звернення в суд з позовом у спорах, що виникають із відносин
            між Користувачем сайту і Адміністрацією сайту, обов'язковим є
            пред'явлення претензії (письмової пропозиції про добровільне
            врегулювання спору).
          </H4>
          <H4>
            6.2. Одержувач претензії протягом 30 календарних днів з дня
            отримання претензії, письмово повідомляє заявника претензії про
            результати розгляду претензії.
          </H4>
          <H2>7. ДОДАТКОВІ УМОВИ</H2>
          <H4>
            7.1. Адміністрація сайту має право вносити зміни в справжню Політику
            конфіденційності без згоди Користувача.
          </H4>
          <H4>
            7.2. Нова Політика конфіденційності вступає в силу з моменту її
            розміщення на Сайті інтернет-магазину, якщо інше не передбачено
            новою редакцією Політики конфіденційності.
          </H4>
        </PoliticsInfo>
      </PoliticsBlock>
    </Layout>
  );
};

const PoliticsBlock = styled.div`
  color: #fff;
  width: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  display: flex;
  justify-content: center;
`;
const PoliticsInfo = styled.div`
  width: 80vw;
  margin-top: 40px;
  margin-bottom: 30px;
`;
const H2 = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;
const H4 = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;
const H6 = styled.h6`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
`;

export default OfertaPage;
