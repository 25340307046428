import React from "react";
import Layout from "../Layout/Layout";
import styled from "styled-components";
const OfertaPage = () => {
  return (
    <Layout>
      <PoliticsBlock>
        <PoliticsInfo>
          <section id="delivery">
            <h1 class="mainItem itemMobile">Договір публічної оферти</h1>
            <div class="block staticPageBlock container" id="request">
              <p></p>
              <p>
                За цим Договором одна сторона Продавець, з одного боку, і
                будь-яка особа, яка прийняла умови даного Договору публічної
                оферти – Покупець, з іншого боку, далі разом – Сторони, уклали
                даний Договір публічної оферти (далі — Договір), адресований
                необмеженому колу осіб, який є офіційною публічною пропозицією
                Продавця укласти з Покупцями договір купівлі-продажу Товарів,
                фотографії яких розміщені у відповідному розділі Веб-сайту&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                &nbsp;
              </p>

              <p>
                Продавці, що мають намір здійснювати продаж Товарів за допомогою
                Веб-сайту&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                &nbsp;&nbsp;та Покупці при придбанні Товарів, зображення яких
                розміщені на відповідних сторінках Веб-сайту&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                &nbsp;, приймають умови цього Договору про нижченаведене.
              </p>

              <ol>
                <li> ЗАГАЛЬНІ ПОЛОЖЕННЯ</li>
              </ol>

              <p>
                <strong>1.1.</strong>
                <strong>&nbsp;</strong>Договірні відносини між Продавцем і
                Покупцем оформляються у вигляді Договору публічної оферти.
                Натискання на сторінці Веб-сайту&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                &nbsp;у відповідному розділі кнопки “ЗАМОВИТИ”, означає, що
                Покупець, незалежно від статусу (фізична особа, юридична особа,
                фізична особа — підприємець), згідно чинного міжнародного та
                українського законодавства, взяв до виконання умови Договору
                публічної оферти, які вказані нижче.
              </p>

              <p>
                <strong>1.2.</strong>
                <strong>&nbsp;</strong>Договір публічної оферти є публічним,
                тобто відповідно до статей 633, 641 Цивільного кодексу України
                його умови однакові для всіх Покупців незалежно від статусу
                (фізична особа, юридична особа, фізична особа — підприємець).
                При повній згоді з даним Договором Покупець приймає умови та
                порядок оформлення замовлення, оплати та доставки товару
                Продавцем, відповідальності за недобросовісне Замовлення і за
                невиконання умов цього Договору.
              </p>

              <p>
                <strong>1.3.</strong>
                <strong>&nbsp;</strong>Даний Договір набуває чинності з моменту
                натискання на кнопку “ЗАМОВИТИ”, яким Покупець надає згоду
                здійснити покупку наявного у Продавця Товару та діє до моменту
                отримання Покупцем Товару від Продавця і повного розрахунку з
                ним.
              </p>

              <p>
                <strong>1.4.</strong>
                <strong>&nbsp;</strong>Для регулювання договірних правовідносин
                за Договором Сторони обирають та, в разі необхідності,
                застосовують українське законодавство. Якщо міжнародним
                договором, згода на обов’язковість якого надана Верховною Радою
                України, встановлені інші правила, ніж ті, що встановлені
                українським законодавством, то застосовуються правила
                міжнародного договору.
              </p>

              <p>2. ТЕРМІНИ І ВИЗНАЧЕННЯ</p>

              <p>
                <strong>«Договір публічної оферти»</strong>&nbsp;– публічний
                договір, зразок якого розміщений на Веб-сайті&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                , і застосування якого є обов’язковим для всіх Продавців, що
                містить пропозицію Продавця щодо придбання Товару, зображення
                якого розміщено на Веб-сайті&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                , спрямовану невизначеному колу осіб, у тому числі Покупцям.
              </p>

              <p>
                <strong>«Акцепт»</strong>&nbsp;– прийняття Покупцем пропозиції
                Продавця щодо придбання Товару, зображення якого розміщено на
                Веб-сайті&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                , шляхом додавання його у віртуальний кошик та відправлення
                Замовлення.
              </p>

              <p>
                <strong>«Товар»</strong>&nbsp;– предмет торгівлі (виріб, модель,
                аксесуар, комплектуючі та супровідні предмети, будь-які інші
                предмети торгівлі), щодо придбання якого на Веб-сайті&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                &nbsp;розміщена пропозиція Продавця.
              </p>

              <p>
                <strong>«Покупець»</strong>&nbsp;– будь-яка дієздатна фізична,
                юридична особа, фізична особа-підприємець, згідно чинного
                українського законодавства, що відвідали Веб-сайт&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                &nbsp;та мають намір придбати той чи інший Товар.
              </p>

              <p>
                <strong>«Продавець»</strong>&nbsp;– будь-яка дієздатна фізична,
                юридична особа, фізична особа-підприємець, згідно чинного
                міжнародного та українського законодавства, які є власниками чи
                розповсюджувачами Товару та за допомогою Веб-сайту&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua//
                </a>
                &nbsp;мають намір його продати.
              </p>

              <p>
                <strong>«Замовлення»</strong>&nbsp;– належно оформлена та
                розміщена на Веб-сайті&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                &nbsp;заявка Покупця на купівлю Товарів, що адресується
                Продавцю.
              </p>

              <p>
                <strong>«Законодавство»</strong>&nbsp;– встановлені українським
                або міжнародним законодавством норми для регулювання договірних
                правовідносин за Договором.
              </p>

              <p>3. ПРЕДМЕТ ДОГОВОРУ</p>

              <p>
                <strong>3.1.</strong>
                <strong>&nbsp;</strong>Продавець зобов’язується на умовах та в
                порядку, визначених цим Договором, продати Товар на основі
                Замовлення, оформленого Покупцем на відповідній сторінці
                Веб-сайту&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                , а Покупець зобов’язується на умовах та в порядку, визначених
                цим Договором, придбати Товар і сплатити за нього кошти.
              </p>

              <p>
                <strong>3.2.</strong>
                <strong>&nbsp;</strong>Продавець та Покупець підтверджують, що
                чинний Договір не є фіктивною чи удаваною угодою або угодою,
                укладеною під впливом тиску чи обману.
              </p>

              <p>
                <strong>3.3.</strong>
                <strong>&nbsp;</strong>Продавець підтверджує, що має всі
                необхідні дозволи на здійснення господарської діяльності, які
                регулюють сферу правовідносин, що виникають та діють в процесі
                виконання цього Договору, а також гарантує, що має право на
                виробництво та/або реалізацію товару без будь-яких обмежень, у
                відповідності з вимогами діючого законодавства України, та
                зобов’язується нести відповідальність у випадку порушення прав
                Покупця в процесі виконання цього Договору та реалізації Товару.
              </p>

              <p>4. ПРАВА ТА ОБОВЯЗКИ ПРОДАВЦЯ</p>

              <p>
                <strong>4.1.&nbsp;</strong>Продавець зобов’язаний:
              </p>

              <ul>
                <li>
                  <div>
                    <li> виконувати умови даного Договору;</li>
                    <li>
                      виконувати замовлення Покупця в разі надходження оплати
                      від Покупця;
                    </li>
                    <li>
                      передати Покупцю Товар згідно з вибраним зразком на
                      відповідній сторінці Веб-сайту&nbsp;
                      <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                        https://sushizlyubovyu.com.ua/
                      </a>
                      , оформленим Замовленням і умовами даного Договору;
                    </li>
                    <li>
                      перевірити якісні і кількісні характеристики Товару під
                      час його пакування;
                    </li>
                    <li>
                      повідомити покупця про можливу додаткову комісію при
                      оплаті європейських замовлень картками типу Mastercard,
                      Visa, Visa Electron, Mastercard Electronic, Maestro шляхом
                      інформування в даному договорі.
                    </li>
                  </div>
                </li>
              </ul>

              <p>
                <strong>4.2.&nbsp;</strong>Продавець має право:
              </p>

              <ul>
                <li>
                  <ul>
                    <li>
                      в односторонньому порядку призупинити надання послуг за
                      цим Договором у випадку порушення Покупцем умов цього
                      Договору.
                    </li>
                  </ul>
                </li>
              </ul>

              <p>
                <strong>4.3.&nbsp;</strong>Продавець:
              </p>

              <ul>
                <li> ФОП&nbsp;</li>
              </ul>

              <p>
                790,м.Львів, вул.
                <br />
                ЄДРПОУ:&nbsp;
                <br />
                Банківські реквізити:
                <br />
                UA
              </p>

              <div>
                <li>
                  або Суб’єкт господарювання з числа осіб, що пропонують до
                  придбання на Веб-сайті&nbsp;
                  <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                    https://sushizlyubovyu.com.ua/
                  </a>
                  &nbsp;Товар за єдиними погодженими правилами, викладеними в
                  даному договорі публічної оферти.
                </li>
              </div>

              <p>5. ПРАВА ТА ОБОВЯЗКИ ПОКУПЦЯ</p>

              <p>
                <strong>5.1.&nbsp;</strong>Покупець зобов’язаний:
              </p>

              <ul>
                <li>
                  <div>
                    <li>
                      своєчасно оплатити і отримати замовлення на умовах цього
                      Договору;
                    </li>
                    <li>
                      ознайомитись з інформацією про Товар, яка розміщена на
                      Веб-сайті&nbsp;
                      <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                        https://sushizlyubovyu.com.ua/
                      </a>
                    </li>
                    <li>
                      при отриманні Товару у особи, що здійснила його доставку,
                      впевнитися у цілісності та комплектності Товару шляхом
                      огляду вмісту упаковки. У випадку виявлення пошкоджень чи
                      неповної комплектації Товару – зафіксувати їх в акті, який
                      разом із Покупцем повинна підписати особа, яка здійснила
                      його доставку Покупцеві.
                    </li>
                  </div>
                </li>
              </ul>

              <p>
                <strong>5.2.&nbsp;</strong>Покупець має право:
              </p>

              <ol>
                <li>
                  <div>
                    <li>
                      оформити замовлення на відповідній сторінці
                      Веб-сайту&nbsp;
                      <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                        https://sushizlyubovyu.com.ua/
                      </a>
                      ;
                    </li>
                    <li>
                      вимагати від Продавця виконання умов цього Договору;
                    </li>
                    <li>
                      на інформування про можливу додаткову комісію при оплаті
                      європейських замовлень картками типу Mastercard, Visa,
                      Visa Electron, Mastercard Electronic, Maestro.
                    </li>
                    <li> &nbsp;</li>
                  </div>
                </li>
              </ol>

              <p>
                6. ПОРЯДОК ОФОРМЛЕННЯ ЗАМОВЛЕННЯ
                <br />
                <strong>6.1.</strong>
                <strong>&nbsp;</strong>Покупець самостійно оформлює Замовлення
                на відповідній сторінці Веб-сайту&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                &nbsp;шляхом додавання Товарів у віртуальний кошик за допомогою
                натискання кнопки “У кошик!”, або зробивши замовлення по
                електронній пошті, чи за номером телефону, вказаному в розділі
                контактів Веб-сайту&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
              </p>

              <p>
                <strong>6.2</strong>&nbsp;Замовлення формується протягом дня.
                Продавець зобов’язується здійснити доставку згідно узгодженого
                часу з Покупцем.
              </p>

              <p>
                <strong>7.</strong>ЦІНА ДОГОВОРУ І ПОРЯДОК ОПЛАТИ
              </p>

              <p>
                <strong>7.1.</strong>
                <strong>&nbsp;</strong>Ціна кожного окремого Товару визначається
                Продавцем та зазначається на відповідній сторінці
                Веб-сайту&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                . Ціна Договору визначається шляхом додавання цін всіх обраних
                Товарів поміщених у віртуальний кошик та ціни доставки, яка
                визначається в залежності від способу доставки відповідно до
                умов розділу 8 цього Договору.
              </p>

              <p>
                <strong>7.2.</strong>
                <strong>&nbsp;</strong>Вартість Замовлення може змінюватись в
                залежності від ціни, кількості чи номенклатури товару.
              </p>

              <p>
                <strong>7.3.</strong>
                <strong>&nbsp;</strong>Покупець може оплатити Замовлення
                наступними способами:
              </p>

              <p>
                <strong>1)</strong>
                <strong>&nbsp;</strong>за допомогою банківського переказу грошей
                на поточний рахунок Продавця, що вказаний в рахунку, в т.ч. за
                допомогою Інтернет-банкінгу (Покупець оплачує Замовлення&nbsp; в
                розмірі 100% передплати).
              </p>

              <p>
                <strong>2</strong>
                <strong>)&nbsp;</strong>Кредитною карткою наступного типу:
              </p>

              <ul>
                <li>
                  <div>
                    <li> Visa</li>
                    <li> Visa Electron</li>
                    <li> Mastercard</li>
                    <li> Mastercard Electronic</li>
                    <li> Maestro</li>
                  </div>
                </li>
              </ul>

              <p>
                <strong>3)</strong>
                <strong>&nbsp;</strong>будь-яким іншим способом за домовленістю
                з Продавцем.
              </p>

              <p>
                <strong>Примітка.</strong>&nbsp;При оплаті Покупцем замовлення
                платіжною карткою можливо стягування додаткової комісії
                емітентом цієї картки, зокрема при оплаті Покупцем замовлення
                платіжною карткою можливо стягування додаткової комісії при
                оплаті Покупцем європейських замовлень емітентами карток Visa,
                Mastercard, Visa Electron, Mastercard Electronic, Maestro. При
                натисканні на сторінці Веб-сайту&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                &nbsp;у відповідному розділі кнопки «ЗАМОВИТИ» означає, що
                Продавець повідомив Покупця про можливість стягнення додаткової
                комісії при оплаті Покупцем європейських замовлень, емітентом
                картки Visa, Mastercard, Visa Electron, Mastercard Electronic,
                Maestro.
              </p>

              <p>8.УМОВИ ДОСТАВКИ ТОВАРУ</p>

              <p>
                <strong>8.1</strong>
                <strong>&nbsp;</strong>Покупець отримує Товар за допомогою
                доставки, або отримує його особисто. Порядок оплати та отримання
                зазначений на відповідній сторінці Веб-сайту&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                .
              </p>

              <p>
                <strong>8.2.</strong>
                <strong>&nbsp;</strong>Факт отримання Товару та відсутність
                претензій до якості Товару, який доставляється
                Компаніями-перевізниками, Покупець підтверджує власним розписом
                у&nbsp; видатковій накладній при отриманні Товару. Зі свого
                боку, Продавець гарантує відвантаження Товару
                Компанії-перевізнику у кількості, зазначеній і оплаченій
                Покупцем, в комплектності згідно Замовлення і в належній якості.
              </p>

              <p>
                <strong>8.3.</strong>
                <strong>&nbsp;</strong>У разі відсутності Покупця за адресою
                доставки, вказаної Покупцем в заявці або відмови Покупця від
                отримання Товару з необґрунтованих причини, при доставці
                кур´єром Компанії-перевізника, Товар повертається в місце
                відвантаження. Оплата за послуги Компанії-перевізника
                вираховується із суми, перерахованої Покупцем за Товар. Залишок
                суми повертається Покупцеві на підставі його листа,
                відправленого на
                e-mail:&nbsp;kvadrat.sushipizza@gmail.com&nbsp;із зазначенням
                розрахункового рахунку, на який повинні бути повернуті грошові
                кошти.
              </p>

              <p>
                Всі питання, що виникли в процесі оплати і отримання Товару,
                Покупець може з’ясувати за контактними даними в розділі
                Контакти.
              </p>

              <p>9.УМОВИ ПОВЕРНЕННЯ ТОВАРУ</p>

              <p>
                <strong>9.1.</strong>
                <strong>&nbsp;</strong>Відповідно до ст. 9 Закону України «Про
                захист прав споживачів», Покупець має право на обмін Товару
                належної якості . Покупець має право на обмін Товару із
                врахуванням положень законодавства про підстави та перелік
                Товарів, що не підлягають обміну (поверненню).
              </p>

              <p>
                <strong>9.2.</strong>
                <strong>&nbsp;</strong>У випадку наявності хоча б одного з
                перерахованих недоліків, Покупець зобов’язаний зафіксувати його
                у складеному акті довільної форми. Акт повинен бути підписаний
                Покупцем та особою, що здійснила доставку Товару або Продавця.
                При можливості недоліки повинні бути зафіксовані засобами фото-
                або відеозйомки. Протягом 1 (одного) дня Покупець зобов’язаний
                повідомити менеджера (представника Продавця відповідального за
                оформлення замовлення на Товар) про виявлені недоліки та
                домовитися про заміну Товару, заповнивши при цьому рекламаційну
                форму на повернення Товару на сайті&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                .
              </p>

              <p>
                <strong>9.3.</strong>
                <strong>&nbsp;</strong>Сторони погодили, що у випадку
                недотримання обов’язкових вимог вказаної процедури, визнається
                отримання Покупцем Товару у належному стані – без будь-яких
                механічних пошкоджень та у повній комплектності.
              </p>

              <p>10.ВІДПОВІДАЛЬНІСТЬ СТОРІН ТА ВИРІШЕННЯ СПОРІВ</p>

              <p>
                <strong>10.1.</strong>
                <strong>&nbsp;</strong>Сторони відповідають за невиконання або
                неналежне виконання умов цього Договору в порядку, передбаченому
                цим Договором і, чинним міжнародним та українським
                законодавством.
              </p>

              <p>
                <strong>10.2.</strong>
                <strong>&nbsp;</strong>У разі виникнення спорів, пов’язаних з
                виконанням Сторонами цього Договору, за винятком спорів щодо
                стягнення заборгованості з Покупця, Сторони зобов’язуються
                вирішувати їх шляхом переговорів з дотриманням претензійного
                порядку. Термін розгляду претензії — 7 (сім) календарних днів
                від дати її отримання. По спорах у зв’язку із стягненням
                заборгованості з Покупця дотримання претензійного порядку не
                потрібно.
              </p>

              <p>
                <strong>10.3.</strong>
                <strong>&nbsp;</strong>Усі спори, розбіжності або вимоги, що
                виникають з цього Договору або у зв’язку з ним, у тому числі що
                стосуються його виконання, порушення, припинення або
                недійсності, підлягають вирішенню у відповідному суді згідно
                міжнародного та українського матеріального і процесуального
                права.
              </p>

              <p>11.ФОРС-МАЖОРНІ ОБСТАВИНИ</p>

              <p>
                <strong>11.1.</strong>
                <strong>&nbsp;</strong>Сторони не несуть відповідальності за
                невиконання будь-якого зі своїх зобов’язань, за винятком
                зобов’язань по оплаті, якщо доведуть, що таке невиконання було
                викликано форс-мажорними обставинами, тобто подіями або
                обставинами, дійсно знаходяться поза контролем такої Сторони,
                настали після укладення цього Договору, що носять непередбачений
                і невідворотний характер.
              </p>

              <p>
                До форс-мажорних обставин належать, зокрема, природні
                катаклізми, страйки, пожежі, повені, вибухи, обмерзання, війни
                (як оголошені, так і неоголошені), заколоти, загибель товару,
                затримки перевізників, викликані аваріями або несприятливими
                погодними умовами, небезпеки і випадковості на морі, ембарго,
                катастрофи, обмеження, що накладаються державними органами
                (включаючи розподілу, пріоритети, офіційні вимоги, квоти і
                ціновий контроль), якщо ці обставини безпосередньо вплинули на
                виконання цього Договору.
              </p>

              <p>
                <strong>11.2.</strong>
                <strong>&nbsp;</strong>Сторона, для якої стало неможливим
                виконання зобов’язань за цим Договором через настання
                форс-мажорних обставин, повинна негайно інформувати іншу Сторону
                в письмовому вигляді про виникнення вищевказаних обставин, а
                також протягом 30 (тридцяти) календарних днів надати іншій
                Стороні підтвердження форс-мажорних обставин. Таким
                підтвердженням буде довідка, сертифікат або інший відповідний
                документ, виданий уповноваженим державним органом, розташованим
                за місцем виникнення форс-мажорних обставин.
              </p>

              <p>
                <strong>11.3.</strong>
                <strong>&nbsp;</strong>Час, який потрібен Сторонам для виконання
                своїх зобов’язань за цим Договором, буде продовжено на будь-який
                термін, протягом якого було відкладено виконання через
                перерахованих обставин.
              </p>

              <p>
                <strong>11.4.</strong>
                <strong>&nbsp;</strong>Якщо через дію обставин непереборної сили
                невиконання зобов’язань за цим Договором триває більше трьох
                місяців, кожна із Сторін має право розірвати цей Договір в
                односторонньому порядку, письмово повідомивши про це іншу
                сторону. Незважаючи на настання форс-мажору, перед припиненням
                цього Договору внаслідок форс-мажорних обставин Сторони
                здійснюють остаточні взаєморозрахунки.
              </p>

              <p>
                Незважаючи на настання форс-мажору, перед припиненням цього
                Договору внаслідок форс-мажорних обставин Сторони здійснюють
                остаточні взаєморозрахунки.
              </p>

              <p>12.ІНШІ УМОВИ ДОГОВОРУ</p>

              <p>
                <strong>12.1.</strong>
                <strong>&nbsp;</strong>Інформація, що надається Покупцем є
                конфіденційною. Інформація про Покупця використовується виключно
                в цілях виконання його Замовлення (відправлення повідомлення
                Продавцю про замовлення Товару, надсилання рекламних повідомлень
                і т.д.).
              </p>

              <p>
                <strong>12.2.</strong>
                <strong>&nbsp;</strong>Власним акцептування Договору або
                реєстрацією на Веб-сайті&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                &nbsp;(заповнення реєстраційної анкети) Покупець добровільно
                надає згоду на збір та обробку власних персональних даних з
                наступною метою: дані, що стають відомі використовуватимуться в
                комерційних цілях, в тому числі для обробки замовлень на
                придбання товарів, отримання інформації про замовлення,
                надсилання телекомунікаційними засобами зв’язку (електронною
                поштою, мобільним зв’язком) рекламних та спеціальних пропозицій,
                інформації про акції, розіграші або будь-якої іншої інформації
                про діяльність Веб-сайту&nbsp;
                <a href="https://sushizlyubovyu.com.ua/" target="_blank">
                  https://sushizlyubovyu.com.ua/
                </a>
                .
              </p>

              <p>
                Для цілей, передбачених цим пунктом, Покупцю мають право
                направлятися листи, повідомлення та матеріали на поштову адресу,
                e-mail Покупця, а також відправлятися sms-повідомлення,
                здійснюватися дзвінки на вказаний в анкеті телефонний номер.
              </p>

              <p>
                <strong>12.3.</strong>
                <strong>&nbsp;</strong>Покупець дає право здійснювати обробку
                його персональних даних, у тому числі: поміщати персональні дані
                в бази даних (без додаткового повідомлення про це), здійснювати
                довічне зберігання даних, їх накопичення, оновлення, зміну (у
                міру необхідності). Продавець бере на себе зобов’язання
                забезпечити захист даних від несанкціонованого доступу третіх
                осіб, не поширювати і не передавати дані будь-якій третій
                стороні (крім передачі даних пов’язаним особам, комерційним
                партнерам, особам, уповноваженим Продавцем на здійснення
                безпосередньої обробки даних для зазначених цілей, а також на
                обов’язковий запит компетентного державного органу).
              </p>

              <p>
                <strong>12.4.</strong>
                <strong>&nbsp;</strong>У випадку небажання отримувати розсилку,
                Покупець має право звернутися до Продавця, написавши заяву про
                відмову від отримання рекламних матеріалів, надіславши його на
                поштову або електронну адресу.
              </p>

              <p>
                <strong>12.5.</strong>
                <strong>&nbsp;</strong>Продавець не несе відповідальність за
                зміст і правдивість інформації, що надається Покупцем при
                оформленні замовлення. Покупець несе відповідальність за
                достовірність вказаної при оформленні замовлення інформації.
              </p>

              <p>
                <strong>13. КОНФІДЕНЦІЙНІСТЬ І ЗАХИСТ ІНФОРМАЦІЇ</strong>
              </p>

              <p>
                <strong>13.1.</strong>&nbsp;Покупець дає свою згоду Продавцю на
                збір, обробку, накопичення, зберігання та використання своїх
                персональних даних (ім’я, номер мобільного телефону та інші
                персональні дані), а також передання їх третім особам виключно з
                ціллю та в межах забезпечення Продавцем виконання обов’язків
                покладених на нього відповідно до умов даної оферти. Персональні
                дані Покупця обробляються відповідно до Закону України від 01
                червня 2010 № 2297-VI «Про захист персональних даних».
                Персональні дані Покупців зберігаються в базі даних Продавця.
                Персональні дані Покупців збираються виключно з метою виконання
                умов даної оферти, дотримання норм у сфері регулювання
                податкових відносин, відносин у сфері бухгалтерського обліку та
                відносин у сфері реклами.
              </p>

              <p>
                <strong>13.2.</strong>&nbsp;Продавець має право відправляти
                інформаційні, в тому числі рекламні повідомлення, на мобільний
                телефон Покупця з його згоди. Покупець має право відмовитися від
                отримання рекламної та іншої інформації без пояснення причин
                відмови, направивши письмову заяву про відмову від отримання
                рекламної та іншої інформації Продавцю за адресою, вказаною на
                Сайті. Сервісні повідомлення, що інформують Покупця про
                замовлення і етапи його обробки, відправляються автоматично і не
                можуть бути відхилені Покупцем.
              </p>

              <p>
                <strong>13.3.</strong>&nbsp;Продавець не несе відповідальності
                за відомості, надані Покупцем на Сайті в загальнодоступній
                формі.
              </p>

              <p>
                <strong>13.4.</strong>&nbsp;Продавець вправі здійснювати записи
                телефонних розмов з Покупцем, попередньо повідомивши Покупця про
                такий запис. При цьому Продавець зобов’язується: запобігати
                спробам несанкціонованого доступу до інформації, отриманої в
                ході телефонних переговорів, та/або передачу її третім особам,
                які не мають безпосереднього відношення до виконання Замовлень,
                відповідно до Закону України від 02.10.1992 року № 2657-XII «Про
                інформацію».
              </p>
              <p></p>
              <br />
            </div>
          </section>
        </PoliticsInfo>
      </PoliticsBlock>
    </Layout>
  );
};

const PoliticsBlock = styled.div`
  color: #fff;
  width: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  display: flex;
  justify-content: center;

  margin-top: 40px;
  margin-bottom: 30px;
`;
const PoliticsInfo = styled.div`
  width: 80vw;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export default OfertaPage;
